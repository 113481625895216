import { render, staticRenderFns } from "./ProdutosView.vue?vue&type=template&id=1bed0154&scoped=true&"
import script from "./ProdutosView.vue?vue&type=script&lang=ts&"
export * from "./ProdutosView.vue?vue&type=script&lang=ts&"
import style0 from "./ProdutosView.vue?vue&type=style&index=0&id=1bed0154&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bed0154",
  null
  
)

export default component.exports