import Vue from 'vue';
import Router from 'vue-router';
import HomeView from '../views/InicioView.vue'
import ContatoView from '../views/ContatoView.vue'
import QuemSomosView from '../views/QuemSomosView.vue'
import ServicosView from '../views/ServicoView.vue'
import ProdutosView from '../views/ProdutosView.vue'

Vue.use(Router);


const router = new Router({
    linkActiveClass: 'btn-selecionado',
    routes: [
        {

            path: '/',
            name: 'home',
            component: HomeView
        },
        {

            path: '/inicio',
            name: 'home',
            component: HomeView
        },
        {

            path: '/quem-somos',
            name: 'home',
            component: QuemSomosView
        },
        {

            path: '/servicos',
            name: 'home',
            component: ServicosView
        },
        {

            path: '/produtos',
            name: 'home',
            component: ProdutosView
        },
        {

            path: '/contato',
            name: 'home',
            component: ContatoView
        }
    ]
})

export default router
