

































import Vue from 'vue';
import { Component } from 'vue-property-decorator'
import Cabecalho from '@/layout/cabecalho.vue'
import Rodape from '@/layout/rodape.vue'

@Component({
    components: {
        Cabecalho,
        Rodape
    }
})
export default class App extends Vue {
    mounted() {
        const script = document.createElement('script')
        script.id = 'themejs';
        script.src = `js/theme.js`
        script.async = true
        script.defer = true
        document.head.appendChild(script);
    }
}
