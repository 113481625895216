







































    import Vue from 'vue';
    import { Component } from 'vue-property-decorator'

    interface IDados {
        texto: string;
        foto: string;
        nome: string;
        cargo: string;
    }

    @Component
    export default class TestemunhoComponent extends Vue {
        dados = new Array<IDados>();

        mounted() {
            this.dados.push({
                texto: ` A I9 Digital é uma empresa exemplar, com serviços e produtos de alta qualidade e profissionalismo inigualáveis. Como cliente fiel por mais de 10 anos, posso dizer que eles sempre ofereceram um suporte e sustentação excepcionais para nossos processos de TI e RH, desenvolvendo integrações e funcionalidades inovadoras de forma personalizada para atender às necessidades da nossa empresa.

                                    A equipe da I9 Digital sempre se antecipa aos problemas, entendendo profundamente nossas demandas e proporcionando soluções ágeis e eficientes. Eles estão sempre atualizados com as mais recentes ferramentas de mercado, o que nos dá confiança de que estamos trabalhando com uma empresa que está na vanguarda da tecnologia.

                                    Essa parceria de sucesso já dura mais de 30 anos, e isso se deve à qualidade do trabalho e do atendimento prestados pela I9 Digital. Eles fizeram uma grande diferença em nossa empresa ao longo dos anos, e esperamos continuar a contar com seus serviços por muitos mais anos. Recomendo fortemente a I9 Digital a todas as empresas que buscam um parceiro confiável e altamente competente em tecnologia da informação.`,
                foto: require('@/assets/images/resource/Testemunhos/Testemunha1.jpg'),
                nome: 'Daniele Ferreira',
                cargo: 'Coordenadora de Administração em RH'
            });

            this.dados.push({
                texto: ` Eu tive a oportunidade desenvolver alguns projetos com a I9DigitalTI,  e fiquei impressionado com a qualidade dos seus serviços. A equipe da I9DigitalTI é altamente qualificada e experiente, oferecendo soluções personalizadas e inovadoras para todas as necessidades relacionadas a RH e folha de pagamento. Eles estão sempre prontos para ajudar e dar suporte, garantindo um atendimento de qualidade e eficiente. Além disso, os sistemas desenvolvidos pela empresa são realmente impressionantes. Eles são completos, fáceis de usar e oferecem uma grande variedade de recursos e funcionalidades que atendem a todas as necessidades do setor de RH.
                        A I9DigitalTI é uma empresa de confiança, com uma história de sucesso no mercado de desenvolvimento de sistemas e consultoria de RH. Eu recomendo fortemente esta empresa para qualquer empresa que busca soluções eficazes e de alta qualidade para suas necessidades de RH e folha de pagamento. Eles são verdadeiramente uma referência no mercado e um parceiro confiável para todas as suas necessidades e soluções empresariais.`,
                foto: require('@/assets/images/resource/Testemunhos/Testemunha2.jpg'),
                nome: 'Márcio Marcelo Ramos',
                cargo: 'Gerente de Tecnologia Negócios e Inovação '
            });

            this.dados.push({
                texto: `
A I9DigitalTI,  entre os diversos fornecedores de tecnológica da nossa organização, é o melhor parceiro para desenvolvimento de sistemas e aplicações.

Os profissionais possuem uma vasta experiência no mercado de desenvolvimento de sistemas, o que se reflete na excelência dos produtos e serviços que eles oferecem. Eles são altamente qualificados e estão sempre prontos para ajudar, oferecendo soluções personalizadas e inovadoras que atenderam completamente às necessidades específicas da nossa organização, seguindo fielmente os modelos de governança.

Além disso, a I9DigitalTI valoriza muito a parceria com seus clientes. Eles estão sempre dispostos a ouvir e entender as necessidades de cada empresa, trabalhando em conjunto para encontrar as melhores soluções, ao qual nos proporcionou resultados excelentes e redução de custos,  gerando uma satisfação em todo corpo executivo.
Eu recomendo para qualquer empresa que busca soluções eficazes, intuitivas, atualizadas e de alta qualidade. Eles são verdadeiramente o meu braço direito em qualquer projeto, agradeço muito pela parceria.
`,
                foto: require('@/assets/images/resource/Testemunhos/Testemunha3.jpg'),
                nome: 'Eduardo Alexandre Farias',
                cargo: 'Gerente Executivo de Tecnologia e Aplicações'
            });

            setTimeout(() => {
                (window as any).$('.testimonial_list').owlCarousel({
                    loop: true,
                    autoplay: true,
                    autoplayTimeout: 10000,
                    dots: true,
                    nav: false,
                    navText: ["<i class='fas fa-long-arrow-alt-left''></i>", "<i class='fas fa-long-arrow-alt-right''></i>"],
                    responsive: {
                        0: {
                            items: 2
                        }
                    }
                })
            }, 1000);
        }
    }
