
































































































































































































































































































































































































































































































import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class ContatoView extends Vue {
    ProductScroll()
    {
        window.scroll(0, 600);
    }
}

