














import { Vue, Component } from "vue-property-decorator";
import Video from '@/components/inicio/video.vue'
import QuemSomos from '@/components/inicio/quem-somos.vue'
import Tecnologia from '@/components/inicio/tecnologia.vue'
import Testemunho from '@/components/inicio/testemunho.vue'
import Rodape from '@/components/inicio/rodape.vue'

@Component({
    components: {
        Video,
        QuemSomos,            
        Tecnologia,
        Testemunho,
        Rodape
    }
})
export default class InicioView extends Vue {
        
}
