






































































































































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { TheMask } from 'vue-the-mask'

@Component({
    components: {
        TheMask
    }
})
export default class ContatoView extends Vue {
    nome: string | null = null;
    telefone: string | null = null;
    empresa: string | null = null;
    email: string | null = null;
    mensagem: string | null = null;
    validar: boolean = false;
    sucesso: boolean = false;

    mounted() {
        if (this.$route.query.post === 'sucesso') {
            //alert('Mensagem enviada com sucesso");
            this.sucesso = true;
            setTimeout(() => {
                document.getElementById('mensagem')!.scrollIntoView({ block: "end" });
                setTimeout(() => this.sucesso = false, 10000);
            }, 1500)
        }
    }

    ehValido(campo: string | null) {
        return campo != null && campo.length > 0;
    }

    get todosSaoValidos() {
        return this.ehValido(this.nome) && this.ehValido(this.telefone) &&
            this.ehValido(this.empresa) && this.ehValido(this.email) &&
            this.ehValido(this.mensagem);
    }

    enviar(e: Event) {
        this.validar = true;

        if (!this.todosSaoValidos) {
            e.preventDefault();
            return;
        }
    }
}
