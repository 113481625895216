



































































































































































































































































































































import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class ServicoView extends Vue {
    ServiceScroll()
    {
        window.scroll(0, 460);
    }

    mounted() {
        let elementId = this.$route.query!.link!.toString();
        document.getElementById(elementId)?.scrollIntoView({ block: "center" });
    }
}

