import Vue from 'vue';
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/style.css'
import '@/assets/css/owl.carousel.min.css';
import '@/assets/css/responsive.css';
import '@/assets/css/nivo-slider.css';
import '@/assets/css/animate.css';
import '@/assets/css/animated-text.css';
import '@/assets/css/all.min.css';
import '@/assets/css/flaticon.css';
import '@/assets/css/theme-default.css';
import '@/assets/css/meanmenu.min.css';
import '@/assets/css/owl.transitions.css';
import '@/assets/venobox/venobox.css';
import '@/assets/css/widget.css';
//import * as a from '@/assets/js/vendor/modernizr-3.5.0.min.js';

new Vue({
    render: h => h(App),
    router
}).$mount('#app');
